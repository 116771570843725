const typography = {
  fontFamily: 'Nunito, sans-serif',
  fontWeightMedium: 700,
  fontWeightBold: 800,
  h1: {
    fontWeight: 800,
    fontSize: '2rem',
    letterSpacing: 0,
  },
  h2: {
    fontWeight: 800,
    fontSize: '1.5rem',
    letterSpacing: 0,
  },
  h3: {
    fontWeight: 800,
    fontSize: '1.375rem',
    letterSpacing: 0,
  },
  h4: {
    fontWeight: 800,
    fontSize: '1.25rem',
    letterSpacing: 0,
  },
  h5: {
    fontWeight: 800,
    fontSize: '1.125rem',
    letterSpacing: 0,
  },
  h6: {
    fontWeight: 700,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  subtitle1: {
    letterSpacing: 0,
  },
  subtitle2: {
    letterSpacing: 0,
  },
  body1: {
    letterSpacing: 0,
  },
  body2: {
    letterSpacing: 0,
  },
  button: {
    letterSpacing: 0,
  },
  caption: {
    letterSpacing: 0,
  },
  overline: {
    letterSpacing: 0,
  },
}

export default typography
