import { Box, BoxProps } from '@mui/material'

import { ReactComponent as LogoSvg } from '../assets/logo.svg'
import logoPeer from '../assets/logoPeer.svg'
import logo from '../assets/peerSa.png'

type LogoProps = {
  colored?: boolean
  size?: number
} & BoxProps

const Logo = ({
  colored = false,
  size = 100,
  ...boxProps
}: LogoProps): JSX.Element => {
  return (
    <Box {...boxProps}>
      {/* <LogoSvg
        height={size}
        // width={size}
        style={colored ? { backgroundColor: 'red' } : {}}
      /> */}
      {/* <img src={logo} alt="Logo" style={{ maxHeight: size }} /> */}
      <img src={logoPeer} alt="Logo" style={{ maxHeight: size }} />
    </Box>
  )
}

export default Logo
