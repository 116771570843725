/** Basic public routes */
export const PUBLIC_BASE_ROUTE_URL = ``

/** Public routes */
export const PUBLIC_LANDING_ROUTE = '/'

/** Common routes */
export const COMMON_UNDER_CONSTRUCTION_ROUTE = 'under-construction'
export const COMMON_403_ROUTE = 'forbidden'
export const COMMON_404_ROUTE = 'page-not-found'

/** API routes */
export const API_ROUTE = 'api'
