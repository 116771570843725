/** Basic user routes */
export const ROOT_PERMISSION = 'ROOT'
export const HOME_PERMISSION = 'HOME'

// Dashboard routes
export const DASHBOARD_PERMISSION = 'DASHBOARD'

// Information routes
export const FAQ_PERMISSION = 'FAQ'
export const HELP_PERMISSION = 'HELP'
export const SETTINGS_PERMISSION = 'SETTINGS'

// Profile routes
export const PROFILE_PERMISSION = 'PROFILE'
export const PROFILE_ACTIVITY_PERMISSION = PROFILE_PERMISSION //'PROFILE_ACTIVITY'
export const PROFILE_INFORMATION_PERMISSION = PROFILE_PERMISSION //'PROFILE_INFORMATION'
export const PROFILE_PASSWORD_PERMISSION = PROFILE_PERMISSION //'PROFILE_PASSWORD'
