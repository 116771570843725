/** Basic user routes */
export const ROOT_ROUTE = 'user'
export const HOME_ROUTE = '/'

// Dashboard routes
export const DASHBOARD_ROUTE = 'dashboard'

// Information routes
export const FAQ_ROUTE = 'faq'
export const HELP_ROUTE = 'help'

// Profile routes
export const PROFILE_ROUTE = 'profile'
export const PROFILE_ACTIVITY_ROUTE = '/'
export const PROFILE_INFORMATION_ROUTE = 'information'
export const PROFILE_PASSWORD_ROUTE = 'password'
